@import './src/scss/variables';

.dark-theme {
	.highlight-word .word.current {
		background-color: rgba($dark-color, .2);
	}

	.highlight-letter .word .letter.current {
		background-color: rgba($dark-color, .2);
	}
}