@import './src/scss/variables';
@import './src/scss/app';

.highlight-letter {
  .word {
    .letter {
      &.correct {
        color: $color9;
        opacity: $opacity2;
      }

      &.incorrect {
        color: $color11;
        opacity: $opacity2;
      }
    }

    //.letter.current + .letter {
    .letter.current {
      background-color: rgba($color, .1);
    }

    &.correct .letter {
      color: $color9 !important;
      opacity: $opacity2;
    }

    &.incorrect .letter {
      //color: $color11 !important;
      opacity: $opacity2;
    }
  }
}

.highlight-word {
  .word {
    background-clip: content-box;

    &.current {
      background-color: rgba($color, .1);

      &:has(> .incorrect) {
        //background-color: $color11;
      }
    }

    &.correct {
      color: $color9;
    }

    &.incorrect:not(.current) {
      .letter {
        //color: $color11;
      }
    }

    .letter {
      &.correct {
        color: $color9;
        opacity: $opacity2;
      }

      &.incorrect {
        color: $color11;
        opacity: $opacity2;
      }
    }

    //.letter.current + .letter {
    .letter.current {
      background-color: unset;
    }

    &.correct .letter {
      color: unset;
      opacity: $opacity2;
    }

    &.incorrect .letter {
      //color: unset;
      opacity: $opacity2;
    }
  }
}

.word {
  white-space: nowrap;
  position: relative;
  margin: 0 .5rem 0 0;
  line-height: 1.4;
}

.rtl {
  direction: rtl;
}

.widget__field {
	&-words {
	  font-size: $font-size3;
	  overflow-wrap: break-word;
	  word-break: break-all;
	}

	&-input {
		input {
			width: 100%;
		}
	}
}

@import 'dark-theme';